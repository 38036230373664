import './App.css';

function App() {
  return (
    <div className="App">
      <div className="background">
        <div className="gradient-overlay"></div>
      </div>
      <div className="content">
        <h1 className="coming-soon">
          <span>C</span>
          <span>O</span>
          <span>M</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
          <span>&nbsp;</span>
          <span>S</span>
          <span>O</span>
          <span>O</span>
          <span>N</span>
        </h1>
      </div>
    </div>
  );
}

export default App;
